import React, {useEffect, useState} from 'react';
import {HiOutlineFilter} from "react-icons/hi";
import CountryFilter from "./CountryFilter";
import RegionFilter from "./RegionFilter";
import RegionalAuthorityRoleFilter from "./RegionalAuthorityRoleFilter";
import SectoralPolicyAreasFilter from "./SectoralPolicyAreasFilter";
import Practices from "./Practices";
import {InstantSearch, Configure} from 'react-instantsearch-dom';
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch';
import qs from 'qs';
import InspiringElementsFilter from "./InspiringElementsFilter";
import RoomForImprovementFilter from "./RoomForImprovementFilter";

const searchClient = instantMeiliSearch(
  process.env.MIX_MEILI_URL,
  process.env.MIX_MEILI_PUBLIC_KEY
);

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = location => qs.parse(location.search.slice(1));
const DEBOUNCE_TIME = 300;

export default function PracticesPage(props) {
  const [searchState, setSearchState] = useState(urlToSearchState(props.location))
  let debouncedSetState;
  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetState);
    const filteredSearchState = {...searchState}
    delete filteredSearchState.page
    debouncedSetState = setTimeout(() => {
      props.history.push(
        searchStateToUrl(props, filteredSearchState), filteredSearchState);
    }, DEBOUNCE_TIME);
    setSearchState(searchState);
  };
  useEffect(() => {
    props.history.listen((location, action) => {
      if (location.state?.query) return
      setSearchState(urlToSearchState(location))
    })
  }, [])

  return (
    <InstantSearch
      indexName="practices"
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <Configure hitsPerPage={4}/>
      <div className='container mx-auto px-3 w-full xl:w-10/12 flex mt-10 space-x-7'>
        <div className='max-w-xs w-full'>
          <div className='flex items-center pb-7'>
            <HiOutlineFilter className='w-12 flex-shrink-0 h-full mx-3'/>
            <div>
              <h3 className='text-xl text-red'>Filter the results</h3>
              <h6 className='text-gray-500'>have a better perspective</h6>
            </div>
          </div>
          <CountryFilter/>
          <RegionFilter/>
          <RegionalAuthorityRoleFilter/>
          <SectoralPolicyAreasFilter/>
          <InspiringElementsFilter/>
          <RoomForImprovementFilter/>
        </div>
        <Practices/>
      </div>
    </InstantSearch>

  )
}
