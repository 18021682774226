import React from 'react';
import {RefinementList} from "react-instantsearch-dom";

export default function RegionalAuthorityRoleFilter() {
    return (
        <div className='border-t py-7 space-y-2'>
            <h3 className='text-2xl'>Regional Authority's Role</h3>
            <h6 className='text-gray-500'>Select one or more regional authority's roles</h6>
            <RefinementList attribute="filter_region_roles"/>
        </div>
    )
}
