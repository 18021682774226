import React from 'react';
import {RefinementList} from "react-instantsearch-dom";

export default function SectoralPolicyAreasFilter() {
    return (
        <div className='border-t py-7 space-y-2'>
            <h3 className='text-2xl'>Sectoral Policy Areas</h3>
            <h6 className='text-gray-500'>Select one or more policy areas</h6>
            <RefinementList attribute="filter_sectoral_policy_areas"/>
        </div>
    )
}
