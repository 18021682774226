export const MAP = {
  '#688FC4': [0, 20],
  '#B9CBE3': [21, 40],
  '#EFD6E8': [41, 60],
  '#DEA6CC': [61, 79],
  '#C776B3': [80, 100],
}

export const DEFAULT_COLOR = '#bbbbbb'

export const colorForValue = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return DEFAULT_COLOR
  }
  for (const key in MAP) {
    const [lower, higher] = MAP[key]
    if (value >= lower && value <= higher) {
      return key
    }
  }
  return DEFAULT_COLOR;
}
