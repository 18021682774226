import {Bar} from 'react-chartjs-2'
import {useEffect, useState} from "react";

export default function GraphDisplay({data, indicators, horizontal, groupByIndicator}) {

  // data format: [{name: 'Some name', mipexr_scores: [{tcns: 2}]}]
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])

  const COLORS = [
    '#0000ff',
    '#ffd700',
    '#d3d3d3',
    '#2f4f4f',
    '#adff2f',
    '#a52a2a',
    '#2e8b57',
    '#191970',
    '#ff8c00',
    '#808000',
    '#ff0000',
    '#ba55d3',
    '#00ff7f',
    '#00ffff',
    '#ff00ff',
    '#f0e68c',
    '#00bfff',
    '#dda0dd',
    '#ff1493',
    '#ffa07a',
  ]
  const truncate = (input) => {
    if (input.length > 20) {
      return input.substring(0, 20) + '...';
    }
    return input;
  };
  const setData = () => {
    if (groupByIndicator) {
      setLabels(indicators.map(i => truncate(i.label)))
      setDatasets(data.map((region, index) => ({
        label: region.name,
        backgroundColor: COLORS[index],
        data: indicators.map(i => region.mipexr_scores[i.value])
      })))
    } else {
      setLabels(data.map(r => r.name))
      setDatasets(indicators.map((indicator, index) => ({
        label: indicator.label,
        backgroundColor: COLORS[index],
        data: data.map(r => r.mipexr_scores[indicator.value])
      })))
    }
  }

  useEffect(() => {
    setData()
  }, [data, indicators, horizontal, groupByIndicator])

  return <Bar
    options={{plugins:{legend: {align: 'start'}}, indexAxis: horizontal ? 'y' : 'x', scales: {y: {max: 100}}}}
    data={{labels, datasets}}
  />;
}
