import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import Flag from 'react-world-flags'
import {
  Configure,
  InfiniteHits as Hits,
  Highlight,
  CurrentRefinements,
  Stats,
  SearchBox, SortBy
} from 'react-instantsearch-dom';

function Hit(props) {
  const practice = props.hit
  return (<div key={practice.id} className='mt-5 border rounded-lg hover:shadow-md'>
    <NavLink to={`/practices/${practice.id}`}>
      <div className='flex flex-wrap justify-between p-3 px-4'>
        <h3 className='text-2xl flex-1 flex-shrink-0' style={{minWidth: '17rem'}}>
          <Highlight attribute="title" hit={props.hit}/>
        </h3>
        {practice.regions[0] && <div className='flex-shrink-0 flex items-center space-x-3 ml-auto'>
          <h5
            className='text-gray-500'>{`${practice.regions[0].name}, ${practice.regions[0].country.name}`}</h5>
          <Flag code={practice.regions[0].country.code} className='w-14 rounded-sm'/>
        </div>}
      </div>
      <div className='p-8 pb-0'>
        <div className='flex border-b pb-5'>
          <div className='w-1/2 flex-shrink-0 border-r'>
            <div className='text-sm font-bold text-gray-500'>Sectoral Policy Areas</div>
            <div className='text-xl'>{practice.sectoral_policy_areas.map(v => v.name).join(', ')}</div>
          </div>
          <div className='pl-10'>
            <div className='text-sm font-bold text-gray-500'>Keywords</div>
            <div className='text-xl'>{practice.keywords.map(v => v.name).join(', ')}</div>
          </div>
        </div>
        <div className='flex py-5'>
          <div className='w-1/2 flex-shrink-0 border-r'>
            <div className='text-sm font-bold text-gray-500'>Region's role</div>
            <div className='text-xl'>{practice.region_roles.map(v => v.label).join(', ')}</div>
          </div>
          {practice.implementation_status && <div className='pl-10'>
            <div className='text-sm font-bold text-gray-500'>Implementation status</div>
            <div className='text-xl'>{practice.implementation_status.name}</div>
          </div>}
        </div>
      </div>
      {practice.inspiring_elements.length || practice.room_for_improvements.length ?
        <div className='flex p-8 pt-4'>
          {
            !practice.inspiring_elements.length ? '' :
              <div className='flex-1 flex-shrink-0 mr-10'>
                <div className='text-2xl'>Inspiring elements</div>
                <div className='flex space-x-5 mt-5'>
                  {practice.inspiring_elements.map((ie, index) => (
                    <img key={index} className="w-24" src={ie.image_url} alt={ie.label}/>))}
                </div>
              </div>
          }
          {
            !practice.room_for_improvements.length ? '' :
              <div className='flex-1'>
                <div className='text-2xl'>Room for Improvement</div>
                <div className='flex space-x-5 mt-5'>
                  {practice.room_for_improvements.map((ie, index) => (
                    <img key={index} className="w-24" src={ie.image_url} alt={ie.label}/>))}
                </div>
              </div>}
        </div> : ''
      }
    </NavLink>
  </div>)
}

export default function PracticeList() {
  const {orderBy, setOrderBy} = useState('filter_countries:asc');
  const sortbyItems = [
    {
      value: "practices", label: "Order by relevancy"
    }, {
      value: "practices:filter_countries:asc",
      label: "Order by Country"
    }, {
      value: "practices:filter_regions:asc",
      label: "Order by Region"
    }, {
      value: "practices:title:asc",
      label: "Order by Title"
    }]
  return (
    <div>
      <Configure sort={orderBy}/>
      <SearchBox className="mt-4 w-full"/>
      <div className='flex justify-between mt-2 border-t border-gray-700 pt-4'>
        <div className='text-gray-500'>
          <Stats/>
        </div>
        <SortBy container="#sort-by"
                defaultRefinement="practices"
                className='text-gray-500 outline-none'
                items={sortbyItems}/>
      </div>
      <div>
        {/*<CurrentRefinements*/}
        {/*  clearsQuery={true}*/}
        {/*/>*/}
      </div>
      <div className='mt-10'>
        <h2 className='text-3xl'>Practice List:</h2>
        <Hits hitComponent={Hit}/>
      </div>
    </div>
  )
}
