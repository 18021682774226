import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {DOMAINS} from "../utils/domains";
import DOMPurify from 'dompurify';
import {useLocation} from "react-router";
import Masonry from "react-masonry-css";
import PlayWithData from './PlayWithData'
import '../../../public/js/iframeResizer.min'
import {HiHome} from "react-icons/hi";

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});
const Mipexr = ({pages}) => <div>
  <div id='integrationLab' className='container mx-auto mt-10 px-4'>
    <h1 className='text-3xl text-gray-900 font-semibold pl-4'>
      Measuring and evaluating migrant integration governance at the regional level
    </h1>
    <Masonry breakpointCols={{default: 2, 740: 1}}
             className='my-masonry-grid mt-5'
             columnClassName='my-masonry-grid_column'
    >
      {pages && Object.keys(pages).map((page) => {
        if (page === 'Play with the data') {
          return <article className='border border-gray-300 rounded-lg py-5 px-4 mb-6'>
           <h3 className='text-gray-900 font-semibold text-2xl mb-2'>{page}</h3>
          <PlayWithData/>
          </article>
        }
        return <article className='border border-gray-300 rounded-lg py-5 px-4 mb-6'>
          <h3 className='text-gray-900 font-semibold text-2xl'>{page}</h3>
          <div className='text-gray-600 mt-5 unreset'
               dangerouslySetInnerHTML={{__html: pages[page]}}>
          </div>
        </article>
      })}
    </Masonry>
  </div>
</div>
const Regin = ({pages, evaluationTags}) => <div>
  <div id='integrationLab' className='container mx-auto mt-10 px-4'>
    <div>
      <h1 className='text-3xl text-gray-900'>
        REGIN Integration Lab - Introductory page
      </h1>
      <NavLink to='/practices'
               className='bg-regin border-2 border-regin font-bold hover:bg-white hover:text-regin inline-block mt-2 px-3 py-1 rounded text-white'>
        ENTER DATABASE
      </NavLink>
    </div>
    <div className='flex mt-5 space-x-7'>
      {pages['About'] && <div className='w-1/2'>
        <div className='border border-gray-300 rounded-lg py-5 px-4'>
          <h3 className='text-gray-900 font-bold text-2xl'>About</h3>
          <div className='text-gray-600 unreset mt-5'
               dangerouslySetInnerHTML={{__html: pages['About']}}/>
        </div>
      </div>}
      {pages['Get Involved'] && <div className='w-1/2'>
        <div className='border border-gray-300 rounded-lg py-5 px-4'>
          <h3 className='text-gray-900 font-bold text-2xl'> Get Involved </h3>
          <div className='text-gray-600 unreset mt-5'
               dangerouslySetInnerHTML={{__html: pages['Get Involved']}}/>
        </div>
      </div>}
    </div>
    <div className='mt-14'>
      <h3 className='text-gray-900 text-center text-2xl font-semibold mb-4 uppercase'>Evaluation Framework</h3>
      <div className="border rounded-lg border-gray-300 py-5 px-4 grid gap-10 grid-cols-2">
        {evaluationTags.map(et => <div key="label" className="flex items-center">
          <img className="w-32 mr-4" src={et.image_url} alt={et.label}/>
          <p>{et.description}</p>
        </div>)}
      </div>
    </div>
    <div>
      <div className='flex space-x-7 mt-10'>
        {pages['REGIN Evaluation Framework'] && <div className='w-1/2'>
          <div className='border rounded-lg border-gray-300 py-5 px-4'>
            <h3 className='text-gray-900 text-2xl font-bold'>REGIN Evaluation
              Framework</h3>
            <div className='text-gray-600 unreset mt-6'
                 dangerouslySetInnerHTML={{__html: pages['REGIN Evaluation Framework']}}/>
          </div>
        </div>}
        {pages['REGIN Integration Lab'] && <div className='w-1/2'>
          <div className='border rounded-lg border-gray-300 py-5 px-4'>
            <h3 className='text-gray-900 text-2xl font-bold'>REGIN Integration Lab</h3>
            <div className='text-gray-600 unreset mt-6'
                 dangerouslySetInnerHTML={{__html: pages['REGIN Integration Lab']}}/>
          </div>
        </div>}
      </div>
    </div>
  </div>
</div>
const Page = ({pages, page}) =>
  <div className='container mx-auto mt-10 px-4'>
    <div className='flex space-x-5 pl-5 items-center'>
      <a href={`//${DOMAINS.MIPEXR}`}><HiHome className='w-8 h-auto'/></a>
      <h1 className='text-3xl text-gray-900'>{page.name}</h1>
    </div>
  <Masonry breakpointCols={{default: 2, 740: 1}}
           className='my-masonry-grid mt-5'
           columnClassName='my-masonry-grid_column'
  >
    {Object.keys(pages || {}).map((section, index) =>
      <article key={index} className='border border-gray-300 rounded-lg py-5 px-4 mb-6'>
        <h3 className='text-gray-900 font-semibold text-2xl'>{section}</h3>
        <div className='text-gray-600 mt-5 unreset'
             dangerouslySetInnerHTML={{__html: pages[section]}}>
        </div>
      </article>
    )}
  </Masonry>
</div>

export default function MainPage() {
  const [pages, setPages] = useState(null)
  const [evaluationTags, setEvaluationTags] = useState([])

  const location = useLocation();

  useEffect(() => {
    fetch('/api/pages').then(r => r.json()).then(p => {
      setPages(p.pages)
      setEvaluationTags(p.evaluation_tags)
    })
    setTimeout(() => iFrameResize({log: true}), 1000)
  }, [])

  const MAP = () => ({
    [DOMAINS.MIPEXR]: {
      '/': Mipexr({pages: pages['mipexr_home']['custom_fields'], page: pages['mipexr_home']}),
      '/key-findings': Page({pages: pages['key_findings']['custom_fields'], page: pages['key_findings']})
    },
    [DOMAINS.REGIN]: {
      '/': Regin({evaluationTags: evaluationTags, pages: pages['regin']['custom_fields'], page: pages['regin']})
    }
  })

  return !pages ?
    <div>Loading...</div>
    :
    MAP()[DOMAINS.current()][location.pathname] || <div className='container mx-auto mt-10 px-4'>404 Not found. :(</div>

}
