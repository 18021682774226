import React from 'react';
import Flag from "react-world-flags";
import ReginLogoBw from '../../svgs/regin_logo_bw.svg'
import UniSalento from '../../svgs/UniSalento.png';
import CpmrLogo from '../../svgs/logo_cpmr_h.png';
import ApuliaLogo from '../../svgs/Apulia-Transparent.png';

export default function LabRFooter() {
  return (
    <div className='mt-10 px-4 bg-regin'>
      <div className='container mx-auto text-gray-100 py-7 flex flex-col-reverse lg:flex-row items-start justify-between'>
        <div className="mr-auto lg:w-6/12">
          <div className='flex items-start'>
            <div>
              <Flag code='EU' className='w-20 border border-white'/>
            </div>
            <div className='ml-2'>
              <p className="max-w-lg">The REGIN Project is funded by the European Union's Asylum, Migration and Integration Fund.</p>
            </div>
          </div>
          <div className='mt-6'>
            The content of this website represents the views of the authors only and is their sole
            responsability. The European Commission does not accept any responsability for use that may be made
            of the information it contains.
          </div>
        </div>
        <div className="mr-auto lg:mr-0 mb-4 lg:mb-0">
          <img src={ReginLogoBw} alt="regin logo black and white" className="h-20 mr-auto lg:mr-0 lg:ml-auto mb-3"/>
          <div className="flex flex-wrap items-center space-x-3">
            <img src={UniSalento} alt="regin logo black and white" className="h-16 brightness-200 filter saturate-0"/>
            <img src={ApuliaLogo} alt="regin logo black and white" className="h-16 filter grayscale"/>
            <img src={CpmrLogo} alt="regin logo black and white" className="h-16 invert filter saturate-0 "/>
          </div>
        </div>
      </div>
    </div>
  )
}
