import React from 'react';
import RegionDetailPreview from "./RegionDetailPreview";
import PracticeList from "./PracticeList";

export default function Practices() {
  const region = {
    name: "Campania",
    country: "Italy",
    shortDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  }
  return (
    <div className='w-full'>
      <RegionDetailPreview shortDescription={region.shortDescription} region={region.name} country={region.country}/>
      <PracticeList/>
    </div>
  )
}
