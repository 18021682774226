import React from 'react'
import PlayWithData from "./PlayWithData";

export default function () {
  return (<div className='container mx-auto px-4 pt-10'>
    <h1 className="text-3xl text-gray-900 font-semibold mb-6">
      Play with the data
    </h1>
    <PlayWithData />
  </div>)
}
