import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import ReactPlaceholder from "react-placeholder";
import Flag from "react-world-flags";
import {HiHome} from "react-icons/hi";
import {DOMAINS} from "../utils/domains";
import {Bar} from "react-chartjs-2";
import ReactMarkdown from 'react-markdown'
import {colorForValue} from '../utils/colorForValue'

const MipexProgressBar = ({label, value, hideValue}) => {
  return <div className="flex items-baseline justify-between">
    <div className={`pt-1 grid grid-cols-${label ? '3' : '2'} items-baseline justify-between w-11/12 text-gray-600`}>
      {label && <h3 className='label font-bold'>{label}</h3>}
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-100 col-span-2">
        <div style={{width: `${value || 0}%`, backgroundColor: colorForValue(value)}}
             className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-lg`}/>
      </div>
    </div>
    {!(hideValue && hideValue === true) &&
    <div className='font-bold'>{typeof value === 'undefined' ? 'N/A' : value}</div>}
  </div>
}

export default function RegionDetail() {
  const {regionId} = useParams()
  const [region, setRegion] = useState(null)

  useEffect(() => {
    setRegion(null)
    fetch(`/api/regions/${regionId}`)
      .then(resp => resp.json())
      .then(region => setRegion(region))
      .catch(err => console.log(err));
  }, [regionId])

  const graphOptions = {
    scales: {
      y: {
        suggestedMax: 100,
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }
  let governanceGraphData = null
  let policyCycleGraphData = null
  if (region && 'mipexr_scores' in region) {
    const {actions, actors_relations, resources} = region.mipexr_scores || {}
    governanceGraphData = {
      labels: ['Actions', 'Actors and Relations', 'Resources'],
      datasets: [
        {
          label: '',
          data: [actions, actors_relations, resources],
          backgroundColor: [
            colorForValue(actions),
            colorForValue(actors_relations),
            colorForValue(resources),
          ]
        }
      ]
    }
    const {formulation, policy_output, implementation, evaluation} = region.mipexr_scores || {}
    policyCycleGraphData = {
      labels: ['Formulation', 'Policy output', 'Implementation', 'Evaluation'],
      datasets: [
        {
          label: 'Data',
          data: [formulation, policy_output, implementation, evaluation],
          backgroundColor: [
            colorForValue(formulation),
            colorForValue(policy_output),
            colorForValue(implementation),
            colorForValue(evaluation),
          ]
        }
      ]
    }
  }

  return (
    <ReactPlaceholder className='container mx-auto mt-10 px-4' rows={40} delay={300} type='text' ready={region}>
      {region && <div className='container mx-auto mt-10 px-4'>
        <div className='flex justify-between'>
          <div className='flex space-x-5 pl-5 items-center'>
            <a href={`//${DOMAINS.MIPEXR}`}><HiHome className='w-8 h-auto'/></a>
            <h1 className='text-3xl text-gray-900'>Regional Profile: {region.name}, {region.country.name}</h1>
          </div>
          <div className='flex space-x-4 items-center text-gray-400 font-bold'>
            <span>MIPEX-R score: {region.mipexr_scores?.overall_score || 0}</span>
            <Flag code={region.country.code} className='w-12 rounded-sm'/>
          </div>
        </div>
        <div className='mt-10 border rounded-lg flex flex-col md:flex-row md:space-x-12 space-y-10 md:space-y-0 p-7'>
          <div className="w-full md:w-1/2">
            <h2 className='font-bold text-2xl'>Overall score</h2>
            <MipexProgressBar value={region.mipexr_scores?.overall_score}/>
            <ReactMarkdown className='unreset'>{region.mipexr_presentations?.intro}</ReactMarkdown>
          </div>
          <div className="w-full md:w-1/2 text-sm">
            <div>
              <div className='border-b border-red-200 font-bold text-gray-900'>Governance systems</div>
              <MipexProgressBar label='Actions' value={region.mipexr_scores?.actions}/>
              <MipexProgressBar label='Actors and Relations' value={region.mipexr_scores?.actors_relations}/>
              <MipexProgressBar label='Resources' value={region.mipexr_scores?.resources}/>
            </div>
            <div className="mt-5">
              <div className='border-b border-red-200 font-bold text-gray-900'>Governance process</div>
              <MipexProgressBar label='Formulation' value={region.mipexr_scores?.formulation}/>
              <MipexProgressBar label='Policy output' value={region.mipexr_scores?.policy_output}/>
              <MipexProgressBar label='Implementation' value={region.mipexr_scores?.implementation}/>
              <MipexProgressBar label='Evaluation' value={region.mipexr_scores?.evaluation}/>
            </div>
            <div className="mt-5">
              <div className='border-b border-red-200 font-bold text-gray-900'>Policy focus</div>
              <MipexProgressBar label='Antidiscrimination' value={region.mipexr_scores?.antidiscrimination}/>
              <MipexProgressBar label='Culture and Religion' value={region.mipexr_scores?.culture_and_religion}/>
              <MipexProgressBar label='Education' value={region.mipexr_scores?.education}/>
              <MipexProgressBar label='Healthcare' value={region.mipexr_scores?.healthcare}/>
              <MipexProgressBar label='Housing' value={region.mipexr_scores?.housing}/>
              <MipexProgressBar label='Labour market' value={region.mipexr_scores?.labour_market}/>
              <MipexProgressBar label='Language' value={region.mipexr_scores?.language}/>
              <MipexProgressBar label='Social security and assistance' value={region.mipexr_scores?.social_security_assistance}/>
            </div>
            <div className="mt-5">
              <div className='border-b border-red-200 font-bold text-gray-900'>Target population</div>
              <MipexProgressBar label='TCNs' value={region.mipexr_scores?.tcns}/>
              <MipexProgressBar label='BIPs' value={region.mipexr_scores?.bips}/>
            </div>
          </div>
        </div>
        <div className='mt-10 flex flex-col md:flex-row md:space-x-7 space-y-10 md:space-y-0'>
          {region.mipexr_presentations?.migration_and_integration && <div className='w-full md:w-1/2'>
            <div className='border rounded-lg p-7 space-y-5'>
              <div className='text-2xl font-bold text-gray-900'>
                Migration and integration trends
              </div>
              <ReactMarkdown
                className='unreset'>{region.mipexr_presentations?.migration_and_integration}</ReactMarkdown>
            </div>
          </div>}
          {region.mipexr_presentations?.competences && <div className='w-full md:w-1/2'>
            <div className='border rounded-lg p-7 space-y-5'>
              <div className='text-2xl font-bold text-gray-900'>
                Competences
              </div>
              <ReactMarkdown className='unreset'>
                {region.mipexr_presentations?.competences}
              </ReactMarkdown>
            </div>
          </div>}
        </div>
        {region.mipexr_presentations?.governance_elements && <div className='mt-10 border rounded-lg p-7 space-y-5'>
          <h3 className='text-2xl font-bold'>
            Governance Elements
          </h3>
          <div className='space-y-5'>
            <ReactMarkdown className='unreset'>{region.mipexr_presentations?.governance_elements}</ReactMarkdown>
            <div className="mx-auto w-1/2">
              {governanceGraphData && <Bar options={graphOptions} data={governanceGraphData}/>}
            </div>
          </div>
        </div>}
        <div className='mt-10 flex flex-col md:flex-row md:space-x-7 space-y-10 md:space-y-0'>
          {region.mipexr_presentations?.policy_cycle && <div className='w-full md:w-1/2'>
            <div className='border rounded-lg p-7'>
              <h3 className='text-2xl text-gray-900 font-bold'>Policy Cycle</h3>
              <ReactMarkdown className='unreset'>{region.mipexr_presentations?.policy_cycle}</ReactMarkdown>
              {policyCycleGraphData && <Bar options={graphOptions} data={policyCycleGraphData}/>}
            </div>
          </div>}
          {region.mipexr_presentations?.policy_focus && <div className='w-full md:w-1/2'>
            <div className='border rounded-lg p-7'>
              <h3 className='text-2xl text-gray-900 font-bold'>Policy Focus</h3>
              <ReactMarkdown className='unreset'>{region.mipexr_presentations?.policy_focus}</ReactMarkdown>
            </div>
          </div>}
        </div>
        <div className='mt-10 text-center'>
          <a href={`//${DOMAINS.REGIN}/practices?refinementList[filter_regions][0]=${region.name}`} target="_blank"
             className='border-2 rounded-lg border-red-600 bg-red-100 text-red-500 rounded-full py-3 px-7 text-xl'>
            See Regional Integration Practices
          </a>
        </div>
      </div>}
    </ReactPlaceholder>
  )
}
