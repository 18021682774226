import React from 'react';
import MainPage from "./MainPage";
import Header from "./Header";
import Map from "./Map";
import LabRFooter from "./LabRFooter";
import MipexRFooter from "./MipexRFooter";
import PracticesPage from "./PracticesPage";
import PracticeDetails from "./PracticeDetails";
import RegionDetail from "./RegionDetail";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {DOMAINS} from "../utils/domains";
import PlayWithDataPage from "./PlayWithDataPage";

export default function Main() {
  return (
    <Router>
      <Header/>
      <Map/>
      <Switch>
        <Route exact path="/practices" component={PracticesPage}/>
        <Route path="/practices/:id" component={PracticeDetails}/>
        <Route path="/region/:regionId" component={RegionDetail}/>
        <Route path="/play-with-data" component={PlayWithDataPage}/>
        <Route exact path="/*" component={MainPage}/>
      </Switch>
      {DOMAINS.current(DOMAINS.REGIN) ? <LabRFooter/> : <MipexRFooter/>}
    </Router>
  )
}

