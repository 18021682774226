import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Flag from "react-world-flags";
import {AiOutlineCloseCircle} from 'react-icons/ai'
import Markdown from 'react-markdown'
import {DOMAINS} from "../utils/domains";

export default function RegionDetailPreview() {
  const [region, setRegion] = useState(null)

  useEffect(() => {
    document.addEventListener('region-filtered-map', (e) => {
      setRegion(e.detail ?? null)
    })
  }, [])

  const regionFields = [
    {
      label: "NUTS Code",
      field: "iso"
    },
    {
      label: "Overall population",
      field: "overall_population"
    },
    {
      label: "Non-EU nationals over 15",
      field: "non_eu_over_15"
    },
    {
      label: "Unemployment rate - overall population",
      field: "ur_overall_population"
    },
    {
      label: "Unemployment rate - non-EU nationals",
      field: "ur_non_eu_nationals"
    },
    {
      label: "Unemployment rate - female non-EU nationals",
      field: "ur_female_non_eu"
    },
    {
      label: "Young people Not in Education, Employment or Training (NEET) - nationals",
      field: "neet_nationals"
    },
    {
      label: "NEET - non-EU nationals",
      field: "neet_non_eu"
    },
    {
      label: "NEET - female non-EU nationals",
      field: "neet_female_non_eu"
    },
    {
      label: "People at risk of poverty or social exclusion",
      field: "people_at_risk"
    },
    {
      label: "Urban - rural typology",
      field: "urban_rural_typology.name"
    },
  ]
  return !region ? (
    <div className='bg-gray-100 rounded-lg p-4'>
      <h1 className='font-bold text-xl mb-4'>
        REGIN Integration Lab - Database & Mapping of Regional Integration Practices
      </h1>
      <p className='text-gray-500 mb-4'>
        The Integration Lab database and mapping is a tool for mutual learning and sharing with a focus on innovative
        practices, challenges faced and lessons learnt, with a particular focus on how concrete issues are faced as
        part of migrant integration practices at regional level and the solutions found to overcome them.
      </p>
      <Link to="/" className='text-regin font-medium'>SEE MORE</Link>
    </div>
  ) : (
    <div className="relative bg-gray-100 rounded-lg p-4">
      <Link className="absolute top-1 right-1" to="/practices"><AiOutlineCloseCircle /></Link>
      <div className='flex justify-between mb-8'>
        <div>
          <h3 className='text-xl font-normal'>{region.name}, {region.country.name}</h3>
          {region.website && <a href={region.website} target="_blank" className='uppercase text-regin mt-3'>
            See Region Website
          </a>}
          {region.website && <span className="mx-3">|</span>}
          <a href={`//${DOMAINS.MIPEXR}/region/${region.id}`} target="_blank" className='text-regin uppercase'>See Mipex-r results</a>
        </div>
        <Flag code={region?.country?.code} className="w-24 mr-2"/>
      </div>
      <ul className="grid grid-cols-1 lg:grid-cols-2 auto-rows-fr gap-x-4 mt-4">
        {regionFields.map(({field, label}) => _.get(region, field) &&
          <li key={field} className="flex overflow-hidden" title={label + ":  " + _.get(region, field)}>
            <div className="font-normal text-sm text-gray-500 mr-2 flex">
              <div className="truncate max-w-xs">{label}</div>
              :
            </div>
            <div className="text-regin text-sm max-w-xs flex-1 font-normal truncate">{_.get(region, field)}</div>
          </li>)}
      </ul>
      {region.sources && <div className='mt-7 text-gray-500'>
        <h2 className="text-sm font-normal mb-2">Sources</h2>
        <Markdown>{region.sources}</Markdown>
      </div>}
    </div>
  )
}
