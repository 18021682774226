import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main'
import '../../css/app.css'

function App() {
    return <Main />;
}

export default App;

if (document.getElementById('app')) {
    ReactDOM.render(<App />, document.getElementById('app'));
}
