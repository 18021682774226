import {useEffect} from "react";
import * as L from "leaflet";
import {colorForValue} from "../utils/colorForValue";

export default function MapLegend({map}) {
  useEffect(() => {
    if (map) {
      const legend = L.control({position: "topright"});

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        div.innerHTML = `
<div class="bg-gray-100 border-2 border-white px-2 py-2 rounded">
    <h4 class="uppercase font-bold mb-2">Legend</h4>
    <ul class="text-2xs space-y-1">
      <li class="flex items-center"><span class="w-3 h-3 inline-block mr-3" style="background-color: ${colorForValue(80)}"></span>80-100 - Well-Developed</li>
      <li class="flex items-center"><span class="w-3 h-3 inline-block mr-3" style="background-color: ${colorForValue(61)}"></span>61-79 - Slightly developed</li>
      <li class="flex items-center"><span class="w-3 h-3 inline-block mr-3" style="background-color: ${colorForValue(41)}"></span>41-60 - Half-way developed</li>
      <li class="flex items-center"><span class="w-3 h-3 inline-block mr-3" style="background-color: ${colorForValue(21)}"></span>21-40 - Underdeveloped</li>
      <li class="flex items-center"><span class="w-3 h-3 inline-block mr-3" style="background-color: ${colorForValue(1)}"></span>0-20 - Critically underdeveloped</li>
    </ul>
</div>
            `;
        return div;
      };

      legend.addTo(map);
    }
  }, [map]); //here add map

  return null
}
