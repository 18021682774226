import Select from 'react-select'
import {useEffect, useState} from "react";
import {groupBy, mapObjIndexed} from "rambda";
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import qs from "qs";
import {pluck} from "rambda/immutable";
import {useHistory, useLocation} from "react-router";
import GraphDisplay from './GraphDisplay'
import ReactTooltip from "react-tooltip";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default function PlayWithData() {

  const history = useHistory();
  const location = useLocation();

  const [booted, setBooted] = useState(false)
  const [iframeUrl, setIframeUrl] = useState('')
  const [copied, setCopied] = useState(false)
  const [regions, setRegions] = useState()
  const [groups, setGroups] = useState()
  const [indicators, setIndicators] = useState()

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedIndicators, setSelectedIndicators] = useState([]);

  const [toggleHorizontal, setToggleHorizontal] = useState();
  const [toggleGroupByIndicator, setToggleGroupByIndicator] = useState();

  const groupRegionsByCountry = (regions) => Object.values(mapObjIndexed((regions, country) => ({
    label: country,
    options: regions
  }), groupBy(region => region.country.name, regions)))

  const groupIndicatorsByGroup = (indicators) => Object.values(mapObjIndexed((indicators, group)=> ({
    label: group,
    options: indicators
  }), groupBy(i => i.group, indicators)))

  const getRegions = async () => {
    const response = await fetch('/api/regions')

    if (![200, 301, 302].includes(response.status)) return

    const data = await response.json()
    setRegions(data.filter(r => r.mipexr_scores))
  }

  const getGroups = async () => {
    const response = await fetch('/api/region-groups')

    if (![200, 301, 302].includes(response.status)) return

    const groups = await response.json()
    setGroups(groups.filter(g => g.mipexr_scores))
  }

  const getIndicators = async () => {
    const response = await fetch('/api/graph-indicators')
    if (![200, 301, 302].includes(response.status)) {
      return
    }
    const data = await response.json()
    setIndicators(data)
  }

  const setInitialValues = async () => {
    const query = qs.parse(location.search.substring(1))
    if ('regions' in query) {
      setSelectedRegions(regions.filter(r => query.regions.map(id => parseInt(id)).includes(r.id)))
    }
    if ('groups' in query) {
      setSelectedGroups(groups.filter(i => query.groups.map(id => parseInt(id)).includes(i.id)))
    }
    if ('indicators' in query) {
      setSelectedIndicators(indicators.filter(i => query.indicators.includes(i.value)))
    }
    if ('groupByIndicator' in query) {
      setToggleGroupByIndicator(query.groupByIndicator === 'true')
    }
    if ('horizontal' in query) {
      setToggleHorizontal(query.horizontal === 'true')
    }

    setBooted(true)
  }

  useEffect(() => {
    getRegions()
    getGroups()
    getIndicators()
  }, [])

  useEffect(async () => {
    if (regions && groups && indicators) {
      setInitialValues()
    }
  }, [regions, groups, indicators])

  useEffect(() => {
    if (!booted) return
    const newQuery = {
      regions: pluck('id', selectedRegions),
      groups: pluck('id', selectedGroups),
      indicators: pluck('value', selectedIndicators),
      groupByIndicator: toggleGroupByIndicator,
      horizontal: toggleHorizontal
    }
    history.push({
      path: '/play-with-data',
      search: qs.stringify(newQuery, {encode: false})
    })

    generateEmbedCode(newQuery)
  }, [selectedIndicators, selectedRegions, selectedGroups, toggleHorizontal, toggleGroupByIndicator])

  const generateEmbedCode = (searchObject) => {
    const filters = qs.stringify(searchObject, {encode: false})
    setIframeUrl(`<iframe src="https://${window.location.host}/api/embed?${filters}" style="width:100%"></iframe>`)
  }

  return !booted ? '' : <div>
    <div>
      <label className='font-semibold'> Regions:
        <Select options={groupRegionsByCountry(regions)}
                value={selectedRegions}
                className="mb-3"
                onChange={setSelectedRegions}
                hideSelectedOptions={false}
                placeholder="Select regions"
                closeMenuOnSelect={false}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                isMulti
        />
      </label>
      <label className='font-semibold'> Groups of regions:
        <Select options={groups}
                value={selectedGroups}
                className="mb-3"
                onChange={setSelectedGroups}
                hideSelectedOptions={false}
                placeholder="Select group"
                closeMenuOnSelect={false}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                isMulti
        />
      </label>
    </div>
    <div className="my-3 flex justify-between items-start">
      <label className='w-1/2 font-semibold'> Indicators:
        <Select options={groupIndicatorsByGroup(indicators)}
                value={selectedIndicators}
                onChange={setSelectedIndicators}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder="Select indicators"
                isMulti
        />
      </label>
      <div className='ml-2 flex-col space-y-4'>
        <label className="flex items-center justify-end">
          <span>{toggleHorizontal ? 'Horizontal' : 'Vertical'}</span>
          <Toggle
            className='ml-2 custom-color'
            icons={false}
            checked={toggleHorizontal}
            onChange={({target: {checked}}) => setToggleHorizontal(checked)}/>
        </label>
        <label className="flex items-center justify-end">
          <span>{toggleGroupByIndicator ? 'Group by indicator' : 'Group by regions'}</span>
          <Toggle
            className="ml-2 custom-color"
            icons={false}
            checked={toggleGroupByIndicator}
            onChange={({target: {checked}}) => setToggleGroupByIndicator(checked)}/>
        </label>
        <ReactTooltip id='embed' place='left' effect='solid' clickable={true}>
          <div className='p-2'>
            <p className='mb-2'>Copy and paste this code into any page.</p>
            <div className='flex space-x-2'>
            <textarea readOnly={true} className='w-full text-black font-normal px-2 py-1 rounded'
                      value={iframeUrl}
            />
              {copied ? <button className='text-green-500'>Copied!</button> :
                <CopyToClipboard text={iframeUrl}
                                 onCopy={() => {
                                   setCopied(true)
                                   setTimeout(() => setCopied(false), 3000)
                                 }}>
                  <button>Copy</button>
                </CopyToClipboard>
              }
            </div>
          </div>
        </ReactTooltip>
        <button data-tip data-for='embed' data-event='click'
                className='border-black border rounded px-2 py-1 ml-auto block'>
          Embed
        </button>
      </div>
    </div>
    <GraphDisplay data={[...selectedRegions, ...selectedGroups]} groupByIndicator={toggleGroupByIndicator}
                  horizontal={toggleHorizontal} indicators={selectedIndicators}/>
  </div>
}
