import React, {useEffect, useRef, useState} from 'react'
import {MapContainer, Popup, GeoJSON} from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {NavLink as Link} from "react-router-dom";
import Flag from 'react-world-flags'
import {DEFAULT_COLOR, colorForValue} from "../utils/colorForValue";
import qs from 'qs'
import {withRouter} from 'react-router'
import {DOMAINS} from "../utils/domains";
import MapLegend from "./MapLegend";

export const announceRegion = (region) => document.dispatchEvent(new CustomEvent('region-filtered-map', {detail: region}))

const MipexrRegionPolygon = ({region}) => {
  const DefaultPolygonStyle = {
    color: DEFAULT_COLOR,
    fillColor: DEFAULT_COLOR,
    fillOpacity: 1,
    weight: 1
  }
  const DefaultPolygonHoverStyle = {
    ...DefaultPolygonStyle,
    color: 'white',
    weight: 1
  }
  let popupOpened = false;
  const color = colorForValue(region.mipexr_scores?.overall_score);

  const PolygonStyle = {...DefaultPolygonStyle, fillColor: color, color}
  const PolygonHoverStyle = {
    ...DefaultPolygonHoverStyle,
    fillColor: color,
    color,
    fillOpacity: 1
  }

  const onEachFeature = (feature, layer) => {
    layer.on('mouseover', (e) => e.target.setStyle(PolygonHoverStyle))
    layer.on('mouseout', (e) => popupOpened ? e : e.target.setStyle(PolygonStyle))
    layer.on('popupopen', (e) => e.target.setStyle(PolygonHoverStyle))
    layer.on('popupclose', (e) => e.target.setStyle(PolygonStyle))
  }

  return <GeoJSON style={PolygonStyle} data={region.geo_shape}
                  onEachFeature={onEachFeature}>
    <Popup onOpen={() => popupOpened = true} onClose={() => popupOpened = false} maxWidth='300'>
      <div className='flex items-center min-w-md'>
        <div className='flex-1 mr-6'>
          <div className='text-xs text-gray-500 font-bold uppercase mb-2'>{region?.country?.name}</div>
          <div className='text-base font-bold mb-2'>{region.name}</div>
            <Link className='uppercase font-bold' to={`/region/${region.id}`}>See Region Details</Link>
        </div>
        <div className='flex-shrink-0' style={{minWidth: '100px'}}>
          <Flag code={region?.country?.code} className='mx-auto w-24 h-full'/>
        </div>
      </div>
    </Popup>
  </GeoJSON>
}


const RegionPolygon = ({region}) => {
  const PolygonStyle = {
    fillColor: '#36668d',
    color: '#0f4169',
    fillOpacity: 0.7,
    weight: 0.5
  }
  const PolygonHoverStyle = {
    ...PolygonStyle,
    color: '#7e6015',
    fillColor: '#edb52c'
  }
  let popupOpened = false;
  const popup = useRef(null)

  const onEachFeature = (feature, layer) => {
    layer.on('mouseover', (e) => {
      e.target.setStyle(PolygonHoverStyle)
    })
    layer.on('mouseout', (e) => {
      return popupOpened ? e : e.target.setStyle(PolygonStyle) && e
    })
    layer.on('popupopen', (e) => {
      e.target.setStyle(PolygonHoverStyle)
    })
    layer.on('popupclose', (e) => {
      e.target.setStyle(PolygonStyle)
    })
  }

  const onFilterClick = () => {
    popup.current._close()
    announceRegion(region)
  }

  return <GeoJSON style={PolygonStyle} data={region.geo_shape}
                  onEachFeature={onEachFeature}>
    <Popup ref={popup} onOpen={() => popupOpened = true} onClose={() => popupOpened = false} maxWidth='300'>
      <div className='flex items-center min-w-md'>
        <div className='flex-1 mr-6'>
          <div className='text-xs text-gray-500 font-bold uppercase mb-2'>{region?.country?.name}</div>
          <div className='text-base font-bold mb-2'>{region.name}</div>
          <Link className='uppercase sm font-bold' onClick={onFilterClick} to={{
            pathname: '/practices',
            search: `refinementList[filter_regions][0]=${region.name}`
          }}><span className="text-regin">Filter practices</span></Link>
        </div>
        <div className='flex-shrink-0' style={{minWidth: '100px'}}>
          <Flag code={region?.country?.code} className='mx-auto w-24 h-full'/>
        </div>
      </div>
    </Popup>
  </GeoJSON>
}

export default withRouter(function Map(props) {
  const isHostMipexr = DOMAINS.current(DOMAINS.MIPEXR)
  const [map, setMap] = useState(null)
  const center = [48.1351, 11.5820]
  const GoogleMapStyles = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e9e9e9"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 29
        },
        {
          "weight": 0.2
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 18
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dddddd"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dddddd"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "saturation": 36
        },
        {
          "color": "#333333"
        },
        {
          "lightness": 40
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2f2f2"
        },
        {
          "lightness": 19
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 17
        },
        {
          "weight": 1.2
        }
      ]
    }
  ]

  const [regions, setRegions] = useState([]);

  const fetchRegions = async () => {
    const result = await (await fetch('/api/regions')).json()
    if(isHostMipexr) {
      return setRegions(result.filter(r => r.mipexr_scores))
    }
    return setRegions(result)
  }

  useEffect(async () => {
    await fetchRegions()
  }, [])

  let listener = null
  useEffect(() => {
    const search = qs.parse(window.location.search.substring(1))
    const filterRegions = search?.refinementList?.filter_regions
    const regionName = filterRegions?.length > 0 ? filterRegions[0].trim() : null
    if (regionName) {
      const region = _.find(regions, r => r.name.trim() === regionName)
      if (region) announceRegion(region)
    }

    if(listener) listener.unlisten()

    listener = props.history.listen((location, action) => {
      const search = qs.parse(location.search.substring(1))
      const regionName = search.refinementList?.filter_regions[0]?.trim()
      if(!regionName) announceRegion(null)
      const region = _.find(regions, r => r.name.trim() === regionName)
      setTimeout(() => announceRegion(region || null), 300)
    })
  }, [regions])


  return <MapContainer whenCreated={(ref) => setMap(ref)} style={{height: '50vh'}} center={center} zoom={4}
                       scrollWheelZoom={false} className="relative">
    <ReactLeafletGoogleLayer apiKey='AIzaSyCBdB1QhXBlbeLqyJcq0Tq2x_6Wmjxs5Zw' type={'roadmap'}
                             styles={GoogleMapStyles}/>
    {
      regions.map((r, i) => isHostMipexr ?
        <MipexrRegionPolygon key={i} region={r}/>:
        <RegionPolygon key={i} region={r}/>)
    }
    {isHostMipexr && <MapLegend map={map}/>}
  </MapContainer>
})
