import React from 'react';
import {RefinementList} from "react-instantsearch-dom";

export default function RoomForImprovementFilter() {
    return (
        <div className='border-t py-7 space-y-2'>
            <h3 className='text-2xl'>Room for improvement</h3>
            <h6 className='text-gray-500'>Select one or more improvements</h6>
            <RefinementList attribute="filter_improvements"/>
        </div>
    )
}
