import React from 'react';
import {AiOutlineMenu} from "react-icons/ai";
import {NavLink as Link} from "react-router-dom";
import {DOMAINS} from "../utils/domains";
import ReginLogo from '../../svgs/regin.svg'

export default function Header() {
  const text = DOMAINS.current(DOMAINS.MIPEXR) ? ' / MIPEX-R' : ' / Integration Lab'

  return (
    <div className='flex items-center px-3 py-2'>
      <AiOutlineMenu className='md:hidden'/>
      <Link className='text-2xl flex items-center' activeClassName="text-orange-400" to="/"><img src={ReginLogo} alt="" className="w-40"/> <span className="ml-1">{text}</span></Link>
    </div>
  )
}
