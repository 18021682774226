import React, {useEffect, useState} from 'react';
import {HiHome} from "react-icons/hi";
import {useParams} from "react-router";
import ReactMarkdown from 'react-markdown'
import {format, parseISO} from 'date-fns'
import Flag from "react-world-flags";
import {Link} from "react-router-dom";

export default function PracticeDetails() {
  const {id} = useParams();
  const [practice, setPractice] = useState()

  useEffect(() => {
    fetch(`/api/practices/${id}`)
      .then(resp => resp.json())
      .then(practice => setPractice(practice))
      .catch(err => console.log(err));
  }, [])

  return !practice ? <div/> : (
    <div className='container mx-auto mt-10'>
      <div>
        <div className='flex items-start justify-between'>
          <div>
            <div className='flex items-center space-x-3 mb-2'>
              <Link to='/practices'><HiHome className='w-10 h-auto'/></Link>
              <h1 className='text-3xl flex'>{practice.title}</h1>
            </div>
            <div className='flex items-center space-x-3 pl-14'>
              {practice.external_link && <a href={practice.external_link} target="_blank" className='text-blue-500 text-sm uppercase'>See practice website</a>}
            </div>
          </div>
          <div className='text-sm text-gray-500 pt-2'>
            Information updated in {format(parseISO(practice.updated_at), 'LLLL Y')}
          </div>
        </div>
      </div>
      <div className='p-10 mb-10'>
        <div className='flex border-b pb-5'>
          <div className='w-1/2 border-r pr-4'>
            <div className='text-gray-500'>Region / Country</div>
            <div className='text-2xl flex items-center'>
              {practice?.regions.map(region => <span className='flex' key={region.id}>{region.name}, {region.country.name}
                <Flag className='w-12 ml-3 rounded-sm' code={region.country.code}/></span>).reduce((prev, curr) => [prev, <span className='mx-4 text-4xl'>/</span>, curr])}
            </div>
          </div>
          <div className='pl-10'>
            <div className='text-gray-500'>Keywords</div>
            <div className='text-2xl'>{practice.keywords.map(k => k.name).join(', ')}</div>
          </div>
        </div>
        <div className='flex py-5'>
          <div className='w-1/2 border-r pr-4'>
            <div className='text-gray-500'>Region's role</div>
            <div className='text-2xl'>{practice.region_roles.map(k => k.label).join(', ')}</div>
          </div>
          <div className='pl-10 space-y-5'>
            {practice.sectoral_policy_areas.length > 0 && <div>
              <div className='text-gray-500'>Sectoral Policy Areas</div>
              <div className='text-2xl'>{practice.sectoral_policy_areas.map(area => area.name).join(', ')}</div>
            </div>
            }
            <div>
              <div className='text-gray-500'>Implementation status</div>
              <div className='text-2xl'>{practice.implementation_status.name}</div>
            </div>
          </div>
        </div>
      </div>

      {
        (practice.inspiring_elements?.length > 0 || practice.room_for_improvement?.length > 0) &&
        <div>
          <h2 className='text-2xl text-center'>Evaluation Framework</h2>
          <div className='flex border border-gray-300 rounded-lg mt-5 text-gray-700'>
            {
              practice.inspiring_elements?.length > 0 &&
              <div className='py-5 flex-1'>
                <h4 className='text-gray-700 font-bold text-center'>Inspiring Elements</h4>
                <div className='mt-6 space-y-4 border-r'>
                  {practice.inspiring_elements.map(evt => <div key={evt.id} className='flex items-center space-x-5 px-4'>
                      <img src={evt.image_url} className='w-28 h-full' alt={evt.label}/>
                      <div className='text-gray-600'>{evt.pivot.description || evt.description}</div>
                    </div>
                  )}
                </div>
              </div>
            }
            {
              practice.room_for_improvements?.length > 0 &&
              <div className='py-5 flex-1'>
                <h4 className='text-gray-700 font-bold text-center'>Room for Improvement</h4>
                <div className='mt-6 space-y-4'>
                  {practice.room_for_improvements.map(evt => <div key={evt.id} className='flex items-center space-x-5 px-4'>
                      <img src={evt.image_url} className='w-28 h-full' alt={evt.label}/>
                      <div className='text-gray-600'>{evt.pivot.description || evt.description}</div>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        </div>
      }
      {practice.tips_for_transferability && <div className='px-4 mt-10'>
        <h2 className='text-2xl'>Tips for transferability</h2>
        <ReactMarkdown className='mt-5 text-gray-700 unreset'>{practice.tips_for_transferability}</ReactMarkdown>
      </div>
      }
      <div className='mt-10'>
        <div className='flex space-x-5'>
          {practice.description && <div className='w-1/3'>
            <div className='border rounded-lg space-y-5 p-4'>
              <h2 className='text-2xl'>Description</h2>
              <ReactMarkdown className="unreset">{practice.description}</ReactMarkdown>
            </div>
          </div>
          }
          {practice.objective && <div className='w-1/3'>
            <div className='border rounded-lg space-y-5 p-4'>
              <h2 className='text-2xl'>Objective</h2>
              <ReactMarkdown className="unreset">{practice.objective}</ReactMarkdown>
            </div>
          </div>
          }
          {practice.partnership_actors_involved && <div className='w-1/3'>
            <div className='border rounded-lg space-y-5 p-4'>
              <h2 className='text-2xl'>Actors involved</h2>
              <ReactMarkdown className="unreset">{practice.partnership_actors_involved}</ReactMarkdown>
            </div>
          </div>
          }
        </div>
      </div>
      <div className='mt-14 flex space-x-10 px-5'>
        {
          practice.target_group &&
          <div className='w-1/3 border-r pr-10 space-y-5'>
            <div className='text-gray-500'>Target group</div>
            <ReactMarkdown className='text-2xl unreset'>{practice.target_group}</ReactMarkdown>
          </div>
        }
        {
          practice.duration &&
          <div className='w-1/3 border-r pr-10 space-y-5'>
            <div className='text-gray-500'>Duration</div>
            <ReactMarkdown className='text-2xl unreset'>{practice.duration}</ReactMarkdown>
          </div>
        }
        {
          practice.budget_and_funding &&
          <div className='w-1/3 space-y-5'>
            <div className='text-gray-500'>Budget and Funding</div>
            <ReactMarkdown className='text-2xl unreset'>{practice.budget_and_funding}</ReactMarkdown>
          </div>
        }
      </div>
      <div className='mt-14 flex space-x-5'>
        {
          practice.main_issues_challenges &&
          <div className='flex-1 flex-shrink-0 border rounded-lg space-y-5 p-4'>
            <h4 className='text-2xl'>Main issues/challenges</h4>
            <ReactMarkdown className='text-gray-700 unreset'>{practice.main_issues_challenges}</ReactMarkdown>
          </div>
        }
        {
          practice.solutions_found &&
          <div className='flex-1 flex-shrink-0 border rounded-lg space-y-5 p-4'>
            <h4 className='text-2xl'>Solutions found</h4>
            <ReactMarkdown className='text-gray-700 unreset'>{practice.solutions_found}</ReactMarkdown>
          </div>
        }
      </div>
      {
        practice.outputs_results_achievements &&
        <div className='mt-14 p-4 space-y-5 border rounded-lg'>
          <h4 className='text-2xl'>Outputs/Results/Achievements</h4>
          <ReactMarkdown className='text-gray-700 unreset'>{practice.outputs_results_achievements}</ReactMarkdown>
        </div>
      }
    </div>
  )
}
