import React from 'react';
import {RefinementList} from "react-instantsearch-dom";

export default function CountryFilter() {
    return (
        <div className='border-t py-7 border-gray-600 space-y-2'>
            <h3 className='text-2xl'>Country</h3>
            <h6 className='text-gray-500'>Select one or more countries</h6>
            <RefinementList attribute="filter_countries"/>
        </div>
    )
}
